<template>
  <div>
    <div class="form-row">
      <div class="form-group">
        <v-select
          :placeholder="
            (!filteredTime.length && formData.date) || !(dates || {}).length
              ? 'Немає вільних місць'
              : 'Час відвідування'
          "
          :options="filteredTime"
          :disabled="!filteredTime.length || !(dates || {}).length"
          v-model="form.time"
        ></v-select>
        <div v-if="$v.form.time.$error" class="error">Обов'язкове поле</div>
      </div>
      <div class="form-group">
        <v-select
          :placeholder="
            (!filteredTime.length && formData.date) || !(dates || {}).length
              ? 'Немає вільних місць'
              : 'Яка кількість людей?'
          "
          :options="quantity"
          :disabled="
            !filteredTime.length || !form.time || !(dates || {}).length
          "
          v-model="form.quantity"
        ></v-select>
        <div v-if="$v.form.quantity.$error" class="error">Обов'язкове поле</div>
      </div>
    </div>
    <div
      class="form-row form-row-person"
      v-for="(item, i) in form.quantity || 1"
      :key="i"
    >
      <div class="form-group">
        <input
          type="text"
          v-model="form.person[i].name"
          placeholder="ПІБ (Повністю)"
        />
        <div
          v-if="($v.form.person.$each[i].name || {}).$invalid && $v.$dirty"
          class="error"
        >
          Обов'язкове поле
        </div>
      </div>
      <div class="form-group">
        <input
          type="text"
          v-model="form.person[i].passport"
          placeholder="Серія, номер паспорту"
        />
        <div
          v-if="($v.form.person.$each[i].passport || {}).$invalid && $v.$dirty"
          class="error"
        >
          Обов'язкове поле
        </div>
      </div>
      <div class="form-group">
        <datepicker
          v-model="form.person[i].birth"
          monday-first
          :language="uk"
          :format="'dd.MM.yyyy'"
          placeholder="Дата народження"
        ></datepicker>
        <div
          v-if="($v.form.person.$each[i].birth || {}).$invalid && $v.$dirty"
          class="error"
        >
          Обов'язкове поле
        </div>
      </div>
    </div>

    <slot></slot>

    <div class="form-submit">
      <button
        type="submit"
        :disabled="$v.$error || promise.isFetching"
        @click.prevent="submit"
        class="button button-white"
      >
        {{ promise.isFetching ? "Відправлення" : "Забронювати екскурсію" }}
      </button>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";
import { apiService } from "../../services/api";
import { uk } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

const initialState = {
  time: null,
  quantity: null,
  person: [{ name: "", passport: "", birth: "" }],
};

export default {
  components: { Datepicker },
  props: ["date", "type", "formData", "dates"],
  data() {
    return {
      uk,
      form: { ...initialState, person: initialState.person },
      promise: { isFetching: false },
    };
  },
  validations: {
    form: {
      time: { required },
      quantity: { required },
      person: {
        required,
        $each: {
          name: { required, minLength: minLength(4), maxLength: maxLength(60) },
          birth: { required },
          passport: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(15),
          },
        },
      },
    },
    formData: {
      phone: { required, minLength: minLength(16) },
      email: { required, email, maxLength: maxLength(40) },
      date: { required },
      type: { required },
      citizen: { required, minLength: minLength(2), maxLength: maxLength(40) },
      agreement: { checked: (value) => !!value },
      agreementRules: { checked: (value) => !!value },
    },
  },
  watch: {
    "form.quantity": function(val) {
      let person = this.form.person.slice(0, val);
      const diff = this.form.quantity - person.length;
      if (diff) {
        for (let i = 0; i < diff; i++) {
          person = person.concat({ name: "", passport: "" });
        }
      }
      this.form.person = person;
    },
  },
  computed: {
    maxQuantity() {
      return (this.type || {}).id === "children" ? 10 : 15;
    },
    quantity() {
      const { time } = this.form;
      if (!time || !time.places) return [];
      let quantity = [];
      for (let i = 0; i < time.places; i++) {
        quantity.push(i + 1);
      }
      return quantity <= this.maxQuantity
        ? quantity
        : quantity.slice(0, this.maxQuantity);
    },
    filteredTime() {
      const { date } = this.formData;
      if (!date || !date.time) return [];
      // TODO: remove when back fix
      let { time } = date;
      if (typeof date.time === "object") {
        time = Object.values(date.time);
      }
      return time
        .map((t) => ({
          ...t,
          label: `${t.value}, ${
            t.places <= this.maxQuantity ? t.places : this.maxQuantity
          } місць доступно`,
        }))
        .filter((z) => !z.is_disabled);
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$error || this.promise.isFetching) return;
      this.promise = { isFetching: true };

      const data = {
        ...this.formData,
        ...this.form,
        date: `${this.formData.date.date} ${this.form.time.value}`,
        type: this.formData.type.id,
        phone: this.formData.phone.replace(/\s/g, ""),
        person: this.form.person.map((p) => ({
          ...p,
          birth: moment(p.birth).format("YYYY-MM-DD"),
        })),
      };
      delete data.time;
      delete data.agreement;
      delete data.agreementRules;

      apiService
        .submit(data)
        .then(() => {
          this.$emit("submit");
          this.promise = { isFetching: false };
        })
        .catch((e) => {
          console.error(e);
          this.$emit("submit", true);
          this.promise = { isFetching: false };
        });
    },
  },
};
</script>
