'use strict';

import smoothscroll from 'smoothscroll-polyfill';
import "vue-select/src/scss/vue-select.scss";
import SVGInject from "@iconfu/svg-inject";

import Vue from "vue";
import Vuelidate from 'vuelidate';
import vSelect from 'vue-select';
import Booking from "./vue/components/Booking.vue";
import VideoPopup from "./vue/components/VideoPopup.vue";
import MapPopup from "./vue/components/MapPopup.vue";
import axios from "axios";
import VueMask from 'v-mask';
import './../scss/common.scss';

smoothscroll.polyfill();
Vue.component('v-select', vSelect);
Vue.use(Vuelidate);
Vue.use(VueMask);

SVGInject(document.querySelectorAll("img.inject"));

const token = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

if (document.getElementById('video-popup')) {
    const videoPopup = new Vue({
        el: "#video-popup",
        components: { VideoPopup },
        template: "<VideoPopup />"
    })
}

if (document.getElementById('map-popup')) {
    const videoPopup = new Vue({
        el: "#map-popup",
        components: { MapPopup },
        template: "<MapPopup />"
    })
}

const booking = new Vue({
    el: "#booking-block",
    components: { Booking },
    template: "<Booking />"
})

function getCoords(elem) {
    var box = elem.getBoundingClientRect();

    return {
        top: box.top + pageYOffset,
        left: box.left + pageXOffset
    };
}

document.getElementById('booking-button').addEventListener('click', function () {
    const el = document.querySelectorAll('.booking-block')[0];
    window.scroll({ top: getCoords(el).top - 80, left: 0, behavior: 'smooth' });
})