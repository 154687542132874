<template>
  <div class="booking-block">
    <div class="booking-block__title">
      Бронювання екскурсій
      <div
        class="booking-block__title-hint"
        v-if="literals.formSubtitle"
        v-html="literals.formSubtitle"
      ></div>
    </div>
    <!-- <div class="booking-block__title">Екскурсії у {{ month }}</div> -->
    <!-- <div class="booking-block__title">Всі екскурсії у вересні і жовтні заброньовані</div> -->
    <form @submit.prevent>
      <div class="form-row" v-if="(form.type || {}).id === 'common'">
        <div class="form-group">
          <label class="checkbox">
            <input type="checkbox" v-model="form.isCitizen" />
            <span class="checkbox__icon"></span>
            Я громадянин України
          </label>
        </div>
        <div class="form-group">
          <input
            type="text"
            v-model="form.citizen"
            placeholder="Громадянство"
          />
          <div v-if="showErrors().citizen" class="error">
            Обов'язкове поле
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <v-select
            placeholder="Тип екскурсії"
            :options="options.types"
            label="value"
            :value="form.type"
            @input="typeHandler"
          ></v-select>
        </div>
        <div class="form-group">
          <BookingDatepicker
            :type="form.type"
            :value="(form.date || {}).date"
            :dates="options.date"
            :disabled="disableCalendar"
            @selected="selectedDate"
          ></BookingDatepicker>
          <div v-if="showErrors().date" class="error">Обов'язкове поле</div>
        </div>
      </div>
      <component
        ref="form"
        :is="showComponent"
        :date="form.date"
        :formData="form"
        :type="form.type"
        :dates="options.date"
        @submit="submit"
      >
        <div class="form-row">
          <div class="form-group">
            <input
              type="text"
              v-mask="'38 0## ### ## ##'"
              v-model="form.phone"
              placeholder="38 0"
            />
            <div v-if="showErrors().phone" class="error">
              Введіть корректний телефон
            </div>
          </div>
          <div class="form-group">
            <input type="text" v-model="form.email" placeholder="Ваш e-mail" />
            <div v-if="showErrors().email" class="error">
              Введіть корректний email
            </div>
          </div>
        </div>
        <div class="form-row-full">
          <div class="form-group">
            <textarea
              v-model="form.additional"
              rows="4"
              :placeholder="textareaPlaceholder"
            ></textarea>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group">
            <label class="checkbox">
              <input type="checkbox" v-model="form.agreement" />
              <span class="checkbox__icon"></span>
              згода на обробку персональних даних
            </label>
            <div v-if="showErrors().agreement" class="error">
              Обов'язкове поле
            </div>
          </div>
          <div class="form-group">
            <label class="checkbox">
              <input type="checkbox" v-model="form.agreementRules" />
              <span class="checkbox__icon"></span>
              <span>
                згода з
                <i
                  class="checkbox__button"
                  @click.prevent.stop="showPopupHandler"
                >
                  правилами відвідування
                </i>
              </span>
            </label>
            <div v-if="showErrors().agreementRules" class="error">
              Обов'язкове поле
            </div>
          </div>
        </div>
      </component>
    </form>
    <div class="error center" v-if="error">{{ error }}</div>
    <div class="thx center" v-if="submited">Дякуємо за Вашу заявку</div>

    <transition name="fade">
      <div class="popup rules-popup" v-if="showRules">
        <div class="popup__bg" @click="showPopupHandler"></div>
        <div class="popup__overlay">
          <div class="popup__inner">
            <button class="popup__close" @click="showPopupHandler"></button>
            <div class="rich-text">
              <p>Забороняється прохід до об’єкта особам з:</p>
              <ul>
                <li>
                  холодною чи вогнепальною зброєю, а також з вибуховими
                  речовинами, засобами підриву, боєприпасами всіх видів і
                  зразків, навчальними або імітаційними боєприпасами,
                  легкозаймистими рідинами чи займистими твердими речовинами,
                  піротехнічними засобами;
                </li>
                <li>отруйними, токсичними та радіоактивними матеріалами;</li>
                <li>ознаками алкогольного або наркотичного сп’яніння;</li>
                <li>
                  валізами, господарськими сумками, рюкзаками, пакетами,
                  плакатами;
                </li>
                <li>тваринами;</li>
                <li>їжею, напоями.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// rules for form are in services/mock-data.js
import { apiService } from "../../services/api";
import BookingDatepicker from "./BookingDatepicker.vue";
import BookingChildren from "./BookingChildren.vue";
import BookingCommon from "./BookingCommon.vue";
import { dictionaries } from "../../services/mock-data";

const initialState = {
  date: null,
  type: null,
  phone: "38 0",
  email: "",
  additional: "",
  isCitizen: false,
  agreement: true,
  agreementRules: true,
};

export default {
  components: {
    BookingDatepicker,
    BookingChildren,
    BookingCommon,
  },
  data() {
    return {
      form: { ...initialState },
      options: {
        date: [],
        types: [],
      },
      error: [],
      isFetching: false,
      submited: false,
      month: dictionaries.month[new Date().getMonth()],
      showRules: false,
      literals: window.literals || {},
    };
  },
  computed: {
    showComponent() {
      if (!this.form.type) return;

      const { id } = this.form.type;
      if (id === "school" || id === "corporative") {
        return "BookingChildren";
      } else {
        return "BookingCommon";
      }
    },
    disableCalendar() {
      return !this.form.type || !this.options.types.length || this.isFetching;
    },
    textareaPlaceholder() {
      return (this.form.type || {}).id === "school"
        ? "Номер школи, місто..."
        : "Додаткова інформація...";
    },
  },
  watch: {
    "form.isCitizen"(val) {
      this.form = { ...this.form, citizen: val ? "Україна" : "" };
      // this.form.citizen = val ? "Україна" : "";
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    showErrors() {
      // refs are not reactive
      if (!this.$refs.form) return {};
      return {
        date: this.$refs.form.$v.formData.date.$error,
        email: this.$refs.form.$v.formData.email.$error,
        phone: this.$refs.form.$v.formData.phone.$error,
        agreement: this.$refs.form.$v.formData.agreement.$error,
        agreementRules: this.$refs.form.$v.formData.agreementRules.$error,
        citizen:
          (this.form.type || {}).id === "common" &&
          this.$refs.form.$v.formData.citizen &&
          this.$refs.form.$v.formData.citizen.$error,
      };
    },
    fetch() {
      if (this.isFetching) return;

      this.setPromise();

      apiService
        .fetchType()
        .then((resp) => {
          this.options.types = resp.data
            .map((z) => ({
              id: z.type,
              value: z.title,
            }))
            .filter((z) => z.id === "common" || z.id === "school");
          this.setPromise(false);
        })
        .catch((e) => {
          console.error(e);
          this.setPromise(false, "Щось пішло не так, перезавантажте сторінку");
        });
    },
    setPromise(isFetching = true, error = "") {
      this.isFetching = isFetching;
      this.error = error;
    },
    selectedDate(val) {
      const date = this.options.date.find((z) => {
        const result =
          new Date(z.date).getDate() === new Date(val).getDate() &&
          new Date(z.date).getMonth() === new Date(val).getMonth();
        return result;
      });
      this.form.date = date;
    },
    typeHandler(val) {
      this.form.type = val;
      this.form.date = null;

      if (!val) return;
      this.setPromise();
      apiService
        .fetchDates(val.id)
        .then((resp) => {
          this.options.date = resp.data;
          this.setPromise(false);
        })
        .catch((e) => {
          console.error(e);
          this.setPromise(false, "Щось пішло не так, перезавантажте сторінку");
        });
    },
    submit(error = false) {
      if (error) {
        this.setPromise(false, "Щось пішло не так, перезавантажте сторінку");
      } else {
        this.setPromise(false, "");
        this.form = { ...initialState };
        this.submited = true;
      }
    },
    showPopupHandler() {
      this.showRules = !this.showRules;
    },
  },
};
</script>
