<template>
  <div>
    <div class="form-row">
      <div class="form-group">
        <v-select
          :placeholder="
            (!filteredTime.length && formData.date) || !(dates || {}).length
              ? 'Немає вільних місць'
              : 'Час відвідування'
          "
          :disabled="!filteredTime.length"
          :options="filteredTime"
          v-model="form.time"
        ></v-select>
        <div v-if="$v.form.time.$error" class="error">Обов'язкове поле</div>
      </div>
      <div class="form-group" v-for="(item, i) in 1" :key="i">
        <input
          type="text"
          v-model="form.person[i].name"
          placeholder="ПІБ (Повністю)"
        />
        <div v-if="$v.form.person.$error" class="error">Обов'язкове поле</div>
      </div>
    </div>

    <slot></slot>

    <div class="form-submit">
      <button
        type="submit"
        :disabled="$v.$error || promise.isFetching"
        @click.prevent="submit"
        class="button button-white"
      >
        {{ promise.isFetching ? "Відправлення" : "Забронювати екскурсію" }}
      </button>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { apiService } from "../../services/api";

const initialState = {
  time: null,
  person: [{ name: "", passport: "", birth: "" }],
};

export default {
  props: ["formData", "dates"],
  data() {
    return {
      form: { ...initialState },
      promise: { isFetching: false },
    };
  },
  validations: {
    form: {
      time: { required },
      person: {
        required,
        $each: {
          name: { required, minLength: minLength(4), maxLength: maxLength(60) },
        },
      },
    },
    formData: {
      phone: { required, minLength: minLength(16) },
      email: { required, email },
      date: { required },
      type: { required },
      agreement: { checked: (value) => !!value },
      agreementRules: { checked: (value) => !!value },
    },
  },
  computed: {
    filteredTime() {
      const { date } = this.formData;
      if (!date || !date.time) return [];

      let { time } = date;
      if (typeof date.time === "object") {
        time = Object.values(date.time);
      }
      return time
        .map((t) => ({
          ...t,
          label: t.value,
        }))
        .filter((z) => !z.is_disabled);
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$error || this.promise.isFetching) return;
      this.promise = { isFetching: true };

      const data = {
        ...this.formData,
        ...this.form,
        date: `${this.formData.date.date} ${this.form.time.value}`,
        type: this.formData.type.id,
        phone: this.formData.phone.replace(/\s/g, ""),
      };
      delete data.time;
      delete data.agreement;
      delete data.agreementRules;
      delete data.citizen;

      apiService
        .submit(data)
        .then(() => {
          this.$emit("submit");
          this.promise = { isFetching: false };
        })
        .catch((e) => {
          console.error(e);
          this.$emit("submit", true);
          this.promise = { isFetching: false };
        });
    },
  },
};
</script>