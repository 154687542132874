<template>
  <Datepicker
    monday-first
    :value="value"
    :language="uk"
    :disabled="disabled || !(dates || {}).length"
    :disabled-dates="disabledDates"
    :format="'dd.MM.yyyy'"
    maximum-view="day"
    :placeholder="
      !(dates || {}).length ? 'Немає вільних місць' : 'Оберіть дату екскурсії'
    "
    @selected="selected"
  ></Datepicker>
</template>

<script>
import { uk } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },
  props: {
    value: {
      type: String || null,
      default: null,
    },
    type: {
      type: Object || null,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dates: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      uk: uk,
    };
  },
  computed: {
    disabledDates() {
      const yesterday = new Date().getDate();
      const month = new Date().getMonth();
      const year = new Date().getFullYear();
      const featuredMonth = (this.type || {}).id === "common" ? 3 : 2;

      return {
        to: new Date(year, month, yesterday),
        from: new Date(year, month + featuredMonth),
        dates: this.getDatesBetweenDates(
          new Date(year, month, yesterday),
          new Date(year, month + featuredMonth)
        ).reduce((accum, d) => {
          if (
            !this.dates.some(
              (dObj) =>
                new Date(dObj.date).getDate() === d.getDate() &&
                new Date(dObj.date).getMonth() === d.getMonth()
            )
          ) {
            return accum.concat(d);
          }
          return accum;
        }, []),
      };
    },
  },
  methods: {
    getDatesBetweenDates(startDate, endDate) {
      let dates = [];
      //to avoid modifying the original date
      const theDate = new Date(startDate);
      while (theDate < endDate) {
        dates = [...dates, new Date(theDate)];
        theDate.setDate(theDate.getDate() + 1);
      }
      return dates;
    },
    selected(val) {
      this.$emit("selected", val);
    },
  },
};
</script>