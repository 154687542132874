<template>
  <div>
    <button type="button" class="video-button" @click="showPopupHandler">
      <img src="/assets/images/play.svg" alt="play" />
    </button>

    <transition name="fade">
      <div class="popup video-popup" v-if="showPopup">
        <div class="popup__bg" @click="showPopupHandler"></div>
        <div class="popup__overlay">
          <div class="popup__inner">
            <button class="popup__close" @click="showPopupHandler"></button>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/CZTAurrbtjA"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: false,
    };
  },
  methods: {
    showPopupHandler() {
      this.showPopup = !this.showPopup;
      if (this.showPopup) {
        document.body.classList.add("popup-opened");
      } else {
        document.body.classList.remove("popup-opened");
      }
    },
  },
};
</script>