<template>
  <div class="footer-map">
    <div class="footer-map__btn" @click="showPopupHandler">
        <img src="/assets/images/map.svg" alt="map">
        <button type="button" class="mobile-only">Дивитись на картi</button>
    </div>

    <transition name="fade">
      <div class="popup video-popup" v-if="showPopup">
        <div class="popup__bg" @click="showPopupHandler"></div>
        <div class="popup__overlay">
          <div class="popup__inner">
            <button class="popup__close" @click="showPopupHandler"></button>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2746.712168489754!2d30.535973802025083!3d50.44838973364887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4cfad45d00d9d%3A0xed19f61774e5a260!2z0JzQsNGA0LjQuNC90YHQutC40Lkg0LTQstC-0YDQtdGG!5e0!3m2!1sru!2sua!4v1598559149026!5m2!1sru!2sua"
              width="600"
              height="450"
              frameborder="0"
              style="border: 0;"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: false,
    };
  },
  methods: {
    showPopupHandler() {
      this.showPopup = !this.showPopup;
      if (this.showPopup) {
        document.body.classList.add("popup-opened");
      } else {
        document.body.classList.remove("popup-opened");
      }
    },
  },
};
</script>