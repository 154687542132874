import { dates, types } from "./mock-data";
import { BASE_URL } from "./CONSTANTS";
import axios from "axios";

const delay = () => new Promise((resolve, reject) => {
    setTimeout(() => resolve(), 1000)
})

class ApiService {
    fetchType() {
        return axios.get(`${BASE_URL}api/types`);
    }
    async fetchDates(type) {
        return axios.get(`${BASE_URL}api/available-booked-dates`, {
            params: {
                type
            }
        });
    }
    async submit(data) {
        return axios.post(`${BASE_URL}api/save`, {
            ...data
        })
    }
}

export const apiService = new ApiService();