// 1. Это общая экскурсия на 15 человек ( пт, сб , вс ) с 10 до 17. В 18.00 - уже закрывается дворец.
// Форма такая как на дизайне  + добавляем выбор по гражданству, 
// по дефолту ставим галочку на украинском гражданстве
// В зависимости от количества выбранного людей, 
// показывает доступные места и поля для заполнения ФИО

// 2.День для организованных групп школьников - четверг 
// ( Нужна простая форма заявки ,Номер школы, город, 
// контактный телефон ,с ними свяжутся для деталей) , 
// думаю можно указать какой чт выбрать 

// 3.Корпоративные , под них бронируется время и 
// другие люди в группу не попадают ( тоже через текстовую форму)

// 4. Экскурсии для детей Сб и Вс на 12:00 - группы до 10 человек. 
// Тоже общая форма с ФИО детей и документ от взрослого предьявить нужно будет.

export const dictionaries = {
    month: [
        'Січні',
        'Лютому',
        'Березні',
        'Квітні',
        'Травні',
        'Червні',
        'Липні',
        'Серпні',
        'Вересні',
        'Жовтені',
        'Листопаді',
        'Грудні'
    ]
}

// TODO: remove me
export const types = [
    {
        type: "common",
        title: "Індивідуальна екскурсія"
    },
    {
        type: "school",
        title: "Шкільна екскурсія (чт)"
    },
    {
        type: "corporative",
        title: "Корпоративна екскурсія"
    },
    {
        type: "children",
        title: "Для дітей (сб, нд о 12:00)"
    },
]

export const dates = [
    {
        id: 1,
        date: "2020-08-24",
        time: [
            {
                value: "11:00",
                places: 3
            },
            {
                value: "12:00",
                places: 5
            },
            {
                value: "15:00",
                places: 3
            },
            {
                value: "16:00",
                places: 15
            },

        ],
    },
    {
        id: 2,
        date: "2020-08-25",
        time: [],
        disabled: true
    },
    {
        id: 3,
        date: "2020-08-26",
        time: [
            {
                value: "11:00",
                places: 3
            },
            {
                value: "12:00",
                places: 5
            },
            {
                value: "15:00",
                places: 3
            },
            {
                value: "16:00",
                places: 15
            },

        ],
    },
    {
        id: 4,
        date: "2020-08-27",
        time: [
            {
                value: "11:00",
                places: 3
            },
            {
                value: "12:00",
                places: 5
            },
            {
                value: "15:00",
                places: 3
            },
            {
                value: "16:00",
                places: 15
            },

        ],
    },
    {
        date: "2020-08-28",
        time: [],
        disabled: true
    },
]

